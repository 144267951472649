<template>
  <section>
    <v-alert
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        class="my-tree-view-style"
      >
        <span slot-scope="{node, path, tree}">
          <input
            id="myCheck"
            type="checkbox"
            :checked="node.$checked"
            @change="onCheckBoxChange(node, tree, path)"
          >
          {{ node.text }}
        </span>
      </base-tree-view-without-actions>
      <v-card-actions>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            :disabled="!isBoardSelected"
            class="mt-2"
            color="#FF3700"
            @click="createBanner()"
          >
            Create
          </v-btn>
        </v-col>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
    <v-btn
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      color="red darken-3"
      @click="$router.push({ name: 'Banners List' })"
    >
      Back
    </v-btn>
  </section>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import moment from 'moment';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    props: {
      bannerFormDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
      };
    },
    computed: {
      banner () {
        return this.$store.getters['banners/getBanner'];
      },
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      bannerFile () {
        return this.$store.getters['banners/getBannerFile'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      isBoardSelected () {
        if (this.selectedDigiBoards.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.loading = false;
    },
    methods: {
       async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },

      async linkDigiBoard () {
        if (this.permissionCheck('link-banner-to-board') === false) {
          this.$router.push({ name: 'Banners List' });
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to link this content with devices. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        await this.$store.dispatch('banners/linkBannerToBoard', {
          banner_id: this.banner.id,
          digi_boards: finalResult,
        }).then(response => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Banner created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Banners List' });
          this.$store.dispatch('banners/clearBanner');
        }).catch(() => {
          this.loading = false;
        });
      },
      convertToGmt (date) {
        return moment(date).utc();
      },
      async createBanner () {
          this.loading = true;
          const schedules = [];
          for (const advancedSchedule of this.advancedScheduleData) {
            schedules.push(advancedSchedule.id);
          }
          var uniqueFileName = '';
          const rotationInterval = [];
          for (const file of this.bannerFile) {
            uniqueFileName = file.formData.unique_file_name;
            rotationInterval.push(file.rotation_interval);
          }
          await this.$store.dispatch('banners/createBanner', {
            unique_file_name: uniqueFileName,
            name: this.bannerFormDetails.bannerName,
            rotation_interval: rotationInterval * 1000,
            start: this.convertToGmt(this.bannerFormDetails.start),
            end: this.bannerFormDetails.end !== null ? this.convertToGmt(this.bannerFormDetails.end) : null,
            schedules: schedules,
          }).then(response => {
            this.$store.dispatch('banners/clearBannerFile');
            this.$store.dispatch('schedule/clearAdvancedSchedule');
            this.linkDigiBoard();
          }).catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
