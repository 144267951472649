<template>
  <section>
    <v-card
      shaped
      color="#ECEFF1"
    >
      <v-form>
        <v-card-text class="justify-center">
          <v-container fluid>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                v-if="showSavedFiles"
                dark
                class="mt-2"
                color="#FF3700"
                @click="savedFiles()"
              >
                <v-icon
                  dark
                  left
                >
                  mdi-image-album
                </v-icon>
                Media Files
              </v-btn>
              <saved-banners-list
                ref="callmethod"
                v-model="showSavedBanners"
              />
            </v-col>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col>
                  <v-text-field
                    v-model="bannerName"
                    label="Banner Name*"
                    hint="Name for your reference - 'ChristmasEve'.(Only alpha dashes allowed)"
                    :rules="[(v) => !!v || 'Please enter the banner name before proceeding.']"
                    type="text"
                    @keydown.space.prevent
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="permissionCheck('upload-content')"
                align="center"
                justify="center"
              >
                <v-col
                  cols="12"
                >
                  <v-file-input
                    v-if="showUpload"
                    v-model="bannerFile"
                    :rules="rules"
                    label="Upload File"
                    hint="Only single files are allowed. File size should be less than 6 Mb."
                    prepend-icon="mdi-camera"
                    persistent-hint
                    :error-messages="serverErrors.get('content_file')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-card v-if="showSelectedFiles">
                    <v-toolbar
                      color="#37474F"
                      dark
                    >
                      <v-toolbar-title class="white--text">
                        Selected File
                      </v-toolbar-title>
                    </v-toolbar>
                    <br>
                    <v-chip
                      v-for="(item, index) in anySelectedFile"
                      :key="index"
                      class="ma-2"
                      dark
                      close
                      color="#E0E0E0"
                      text-color="#000000"
                      @click:close="removeContent()"
                    >
                      <v-card
                        class="my-card-in-v-chip"
                        elevation="0"
                      >
                        {{ item.formData.original_file_name }}
                      </v-card>
                    </v-chip>
                  </v-card>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  color="red darken-3"
                  @click="cancel()"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  color="#FF3700"
                  :disabled="disableNext"
                  @click="uploadBanner()"
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </section>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SavedBannersList from './SavedBannersList.vue';
  import Errors from 'src/errors';
  import global from 'src/mixins/global';
  import Constants from 'src/constants';

  export default {
    name: 'FileUpload',
    components: {
      'centre-spinner': spinner,
      SavedBannersList,
    },
    mixins: [global],
    data () {
      return {
        bannerName: '',
        loading: false,
        bannerFile: [],
        rotationInterval: 5,
        showSavedBanners: false,
        serverErrors: new Errors(),
        valid: true,
        rules: [
          value => value.size < 30000000 || 'File size should be less than 30 MB',
        ],
      };
    },
    computed: {
      anySelectedFile () {
        return this.$store.getters['banners/getBannerFile'];
      },
      showSelectedFiles () {
        return this.anySelectedFile.length > 0;
      },
      showUpload () {
        return this.anySelectedFile.length === 0;
      },
      showSavedFiles () {
        return this.bannerFile.length === 0;
      },
      disableNext () {
        return this.bannerFile.length === 0 && this.anySelectedFile.length === 0;
      },
    },
    mounted () {
      this.$store.dispatch('banners/clearBannerFile');
    },
    methods: {
      savedFiles () {
        if (this.permissionCheck('read-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showSavedBanners = true;
      },
      handleFileUpload () {
        this.bannerFile = this.$refs.bannerFile.files[0];
      },
      async uploadBanner () {
        if (this.$refs.form.validate()) {
          this.loading = true;
          await this.$store.dispatch('banners/createBannerName', {
            name: this.bannerName,
          });
          if (this.bannerFile.length !== 0) {
            const formData = new FormData();
            formData.append('content_file', this.bannerFile);
            formData.append('category_type', 'banner');
            await this.$store.dispatch('banners/uploadBannerFile', {
              formData: formData,
              rotationInterval: this.rotationInterval,
            }).catch(() => {
              this.$router.push({ name: 'Banners List' });
              this.loading = false;
            });
          }
          this.$emit('second-step');
          this.bannerName = '';
          this.loading = false;
        }
      },
      cancel () {
        this.$router.push({ name: 'Banners List' });
      },
      removeContent () {
        this.$refs.callmethod.removeBadge();
        this.$store.dispatch('banners/clearBannerFile');
      },
    },
  };
</script>
<style scoped>
.my-card-in-v-chip {
  background-color: #e0e0e0;
  color: black;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 960px) {
.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 52px;
    line-height: 1.3;
}
.my-card-in-v-chip {
  background-color: #e0e0e0;
  color: black;
  margin: 0px;
  max-width: 150px;
}
}
</style>
